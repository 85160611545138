<template>
<div id="dougaccentbar">
    <containter fluid> 
        <v-row>
          <v-col cols="6">
            <v-row>
            <v-col cols="6" align="right">
                    <v-img
                    width="200"
                    height="200"
                    :src="dougimage"
                    position='top'
                    style="border-radius: 50%"
                    ></v-img>
            </v-col>
            <v-col cols="3" align="left">
                  <h1>Doug Feil</h1>
                  <h2>Licensed Professional Counselor</h2>
                  <p class="subtitle"> Te dolor consul mei, etiam molestie indoctum usu ad, qui inermis eleifend an.</p>
                  <v-btn
                    class="primary"
                    elevation="2"
                    @click="loadaboutme"
                    >More About Doug</v-btn>
            </v-col>
            </v-row>
            </v-col>
             <v-col cols="6">
            <v-row>
            <v-col cols="6" align="right">
                    <v-img
                    width="200"
                    height="200"
                    :src="joyceimage"
                    position='top'
                    style="border-radius: 50%"
                    ></v-img>
            </v-col>
            <v-col cols="3" align="left">
                  <h2>Joyce Feil</h2>
                  <h2>Ministry Consultant</h2>
                  <p class="subtitle"> Te dolor consul mei, etiam molestie indoctum usu ad, qui inermis eleifend an.</p>
                  <v-btn
                    class="primary"
                    elevation="2"
                    @click="loadaboutme"
                    >More About Joyce</v-btn>
            </v-col>
            </v-row>
             </v-col>
        </v-row>
    </containter>
</div>
</template>
<script>
  export default {
    data: () => ({
      dougimage: '/static/doug01.jpeg',
      joyceimage: '/static/joycefeil.jpg'
    }),  methods:{
        loadaboutme(){
            this.$router.push({name: 'About'})
        }
    }
  }
</script>